<template>
  <v-app id="backGND">
    <LoaderSpinner v-if="flagLoading" />
    <div v-else>
      <ProjectFilters style="margin-bottom: 10px" />
      <ProjectContent style="margin-bottom: 20px" /></div
  ></v-app>
</template>
<script>
import ProjectContent from "../components/Projects/ProjectContent.vue";
import ProjectFilters from "../components/Projects/ProjectFilters.vue";
import { ref, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import LoaderSpinner from "../components/CircularLoader.vue";

export default {
  components: {
    ProjectContent,
    ProjectFilters,
    LoaderSpinner,
  },
  setup() {
    const store = useStore();
    const flagLoading = ref(true);
    // const userType = computed(() => store.state.user.type);
    // const userId = computed(() => store.state.user.uid);

    onMounted(async () => {
      try {
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: "projects",
        });
        flagLoading.value = false;
      } catch (error) {
        console.error("Error in getting projects:", error);
      }
    });
    onUnmounted(async () => {
      await store.commit("clearDetails");
      await store.commit("clearPagination");
      await store.commit("clearSelectedTab");
      await store.commit("clearClients");
      await store.commit("clearSelectedProject");
    });

    return {
      flagLoading,
    };
  },
};
</script>
