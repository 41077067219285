<template>
  <div>
    <link
      href="https://fonts.googleapis.com/css2?family=Edu+NSW+ACT+Foundation:wght@400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&family=Workbench&display=swap"
      rel="stylesheet"
    />
    <v-app v-if="$store.state.appLoaded === true" class="relative">
      <NavBar
        v-if="shouldShowAppBar"
        class="absolute top-0 left-0 right-0 z-1 w-full"
      />
      <div class="flex">
        <SideBar
          v-if="shouldShowAppBar"
          class="w-60 bg-blue_primary text-white z-0"
        />
        <v-main
          :class="{ 'with-app-bar': shouldShowAppBar }"
          class="flex-1 p-4"
        >
          <router-view />
        </v-main>
      </div>
      <SnackBar />
      <!-- <GlobalTimer v-if="shouldShowGlobalTimer" /> -->
      <NewGlobalTimer v-if="shouldShowGlobalTimer" />
    </v-app>
    <v-app v-else>
      <CircularLoader />
    </v-app>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import SnackBar from "./components/SnackBar.vue";
import CircularLoader from "./components/CircularLoader.vue";
// import GlobalTimer from "./components/GlobalTimer.vue";
import SideBar from "./components/Bars/SideBar.vue";
import NavBar from "./components/Bars/NavBar.vue";
import NewGlobalTimer from "./components/GlobalTimer/GlobalTimer.vue";
import { auth } from "./firebase/firebaseConfig.js";

export default {
  components: {
    SnackBar,
    CircularLoader,
    // GlobalTimer,
    SideBar,
    NavBar,
    NewGlobalTimer,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const trackerRunning = computed(() => store.getters.isTimerActive);

    const excludedRoutes = ["/login", "/register-v2", "/forgot-password", "/register-resource"];

    const shouldShowAppBar = computed(() => {
  return !excludedRoutes.includes(route.path) && !!auth.currentUser;
});

    const shouldShowGlobalTimer = computed(() => {
      return trackerRunning.value && !excludedRoutes.includes(route.path);
    });

    return {
      shouldShowAppBar,
      shouldShowGlobalTimer,
    };
  },
};
</script>

<style>
#app {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#backGND {
  background-image: url("../public/kamvu/bg.png");
  background-size: 250px 250px;
  background-repeat: no-repeat;
  background-position: bottom right;
  margin-top: auto;  
  z-index: 10;
}


.with-app-bar {
  margin-top: 60px; /* Ajusta este valor según la altura de tu AppBar */
}

.v-field__input {
  color: rgb(var(--v-theme-primary)) !important;
}
.v-field--variant-filled {
  color: rgb(var(--v-theme-primary)) !important;
}
.v-select .v-field .v-field__input {
  background-color: #cde2ff !important;
}
.v-field.v-field--appended {
  background-color: #cde2ff !important;
}
</style>
