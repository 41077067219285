<template>
  <v-app>
    <LoaderSpinner v-if="flagLoading" />
    <div class="table-container" v-else>
      <ClientDescription style="margin-bottom: 30px" />
      <ClientTabs
        @tab-selected-client-management="handleTabSelected"
        style="margin-bottom: 30px"
      />
      <ClientProjectsTable
        v-if="selectedTab === 'Projects'"
        style="margin-bottom: 30px"
      />
      <ClientInvoicesTable
        v-if="selectedTab === 'Invoices'"
        style="margin-bottom: 30px"
      />
    </div>
  </v-app>
</template>
    
    <script>
import { ref, onUnmounted, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ClientDescription from "@/components/ClientManagement/ClientDescription.vue";
import ClientTabs from "@/components/ClientManagement/ClientTabs.vue";
import ClientProjectsTable from "@/components/ClientManagement/ClientProjectsTable.vue";
import ClientInvoicesTable from "@/components/ClientManagement/ClientInvoicesTable.vue";

export default {
  components: {
    ClientDescription,
    ClientTabs,
    ClientProjectsTable,
    ClientInvoicesTable,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const id_client = route.params.id;
    const flagLoading = ref(true);
    const selectedTab = ref("Projects");

    const handleTabSelected = (tab) => {
      selectedTab.value = tab;
    };

    onMounted(async () => {
      try {
        await store.dispatch("updateSelectedTab", selectedTab.value);
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: "projects",
          id_client: id_client,
        });
        flagLoading.value = false;
      } catch (error) {
        console.error("Error getting tasks:", error);
      }
    });

    onUnmounted(async () => {
      await store.commit("clearDetails");
      await store.commit("clearPagination");
      await store.commit("clearSelectedTab");
      await store.commit("clearClients");
    });
    return { handleTabSelected, selectedTab };
  },
};
</script>
    
    <style scoped>
.table-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2rem;
  margin-right: 2rem;
}


</style>
    