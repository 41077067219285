<template>
  <div
    class="w-full h-[82px] px-4 pt-6 pb-4 justify-between items-center inline-flex"
  >
    <div
      class="p-1 bg-white rounded-lg border border-slate-100 justify-start items-start flex"
    >
      <button
        @click="selectTab('Projects')"
        :class="
          selectedTab === 'Projects'
            ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
            : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
        "
      >
        <div
          :class="
            selectedTab === 'Projects'
              ? 'text-blue-700 text-sm font-semibold'
              : 'text-gray-500 text-sm font-normal'
          "
        >
          Projects
        </div>
      </button>
      <button
        @click="selectTab('Invoices')"
        :class="
          selectedTab === 'Invoices'
            ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
            : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
        "
      >
        <div
          :class="
            selectedTab === 'Invoices'
              ? 'text-blue-700 text-sm font-semibold'
              : 'text-gray-500 text-sm font-normal'
          "
        >
          Invoices
        </div>
      </button>
    </div>
  </div>
</template>
  
  <script>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "UserManagementSearchFilters",
  emits: ["tab-selected-user-management"],
  setup(props, { emit }) {
    const selectedTab = ref("Projects");
    const store = useStore();
    const route = useRoute();
    const id_client = route.params.id;

    const selectTab = async (tab) => {
      await store.dispatch("updateSelectedTab", tab);
      await store.dispatch("fetchTable", {
        page: 1,
        limit: 10,
        type: tab === "Projects" ? "projects" : "invoices",
        id_client: id_client,
      });
      selectedTab.value = tab;
      emit("tab-selected-client-management", tab);
    };

    const toggleModal = () => {
      console.log("open create new task");
    };

    return {
      selectedTab,
      selectTab,
      toggleModal,
    };
  },
};
</script>
  
  <style scoped>
/* Aquí puedes poner estilos específicos si los necesitas */
</style>
  