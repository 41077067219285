<template>
  <v-app id="backGND">
    <LoaderSpinner v-if="flagLoading" />
    <div class="table-containerr" v-else>
      <ProjectDescription style="margin-bottom: 50px" />
      <TaskTabs @tab-selected="handleTabSelected" style="margin-bottom: 30px" />
      <TaskSearchFilters
        v-if="selectedTab === 'Tasks'"
        style="margin-bottom: 30px"
      />
      <TaskTable v-if="selectedTab === 'Tasks'" style="margin-bottom: 30px" />
      <ExpensesSearchFilters
        v-if="selectedTab === 'Expenses'"
        style="margin-bottom: 30px"
      />
      <ExpensesTable
        v-if="selectedTab === 'Expenses'"
        style="margin-bottom: 30px"
      />
      <InvoicesSearchFilters
        v-if="selectedTab === 'Invoices'"
        style="margin-bottom: 30px"
      />
      <InvoicesTable
        v-if="selectedTab === 'Invoices'"
        style="margin-bottom: 30px"
      />
      <TimesheetsSearchFilters
        v-if="selectedTab === 'Timesheets'"
        style="margin-bottom: 30px"
      />
      <TimesheetsTable
        v-if="selectedTab === 'Timesheets'"
        style="margin-bottom: 30px"
      />
      <BillsSearchFilters
        v-if="selectedTab === 'Bills'"
        style="margin-bottom: 30px"
      />
      <BillsTable v-if="selectedTab === 'Bills'" style="margin-bottom: 30px" />
    </div>
  </v-app>
</template>
    
<script>
import { ref, onMounted, onUnmounted } from "vue";
import TaskTabs from "@/components/Projects/GeneralTabs.vue";
import ProjectDescription from "@/components/Projects/DescriptionComponent.vue";
import TaskSearchFilters from "@/components/Projects/Tasks/TaskSearchFilters.vue";
import TaskTable from "@/components/Projects/Tasks/TaskTable.vue";
import ExpensesTable from "@/components/Projects/Expenses/ExpensesTable.vue";
import ExpensesSearchFilters from "@/components/Projects/Expenses/ExpensesSearchFilters.vue";
import InvoicesTable from "@/components/Projects/Invoices/InvoicesTable.vue";
import InvoicesSearchFilters from "@/components/Projects/Invoices/InvoicesSearchFilters.vue";
import TimesheetsTable from "@/components/Projects/Timesheets/TimesheetsTable.vue";
import TimesheetsSearchFilters from "@/components/Projects/Timesheets/TimesheetsSearchFilters.vue";
import BillsTable from "@/components/Projects/Bills/BillsTable.vue";
import BillsSearchFilters from "@/components/Projects/Bills/BillsSearchFilters.vue";

import LoaderSpinner from "../components/CircularLoader.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
export default {
  components: {
    TaskTabs,
    ProjectDescription,
    TaskSearchFilters,
    TaskTable,
    ExpensesTable,
    ExpensesSearchFilters,
    InvoicesTable,
    InvoicesSearchFilters,
    TimesheetsTable,
    TimesheetsSearchFilters,
    LoaderSpinner,
    BillsTable,
    BillsSearchFilters,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const id_project = route.params.id;
    const flagLoading = ref(true);
    const selectedTab = ref("Tasks");

    const handleTabSelected = async (tab) => {
      selectedTab.value = tab;
      await store.dispatch("updateSelectedTab", selectedTab.value);

      if (tab == "Expenses") {
        await store.commit("setSelectedType", "Submitted");
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: tab.toLowerCase(),
          searchType: ["Submitted"],
          id_project: id_project,
        });
      } else if (tab == "Tasks") {
        await store.commit("setSelectedType", "Active");
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: tab.toLowerCase(),
          searchType: ["In progress", "Not Started"],
          id_project: id_project,
        });
      } else if (tab == "Invoices") {
        await store.commit("setSelectedType", "Submitted");
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: "invoices",
          searchType: ["Submitted"],
          id_project: id_project,
        });
      } else if (tab == "Timesheets") {
        await store.commit("setSelectedType", "Submitted");
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: "timesheets",
          searchType: ["Submitted"],
        });
      } else if (tab == "Bills") {
        await store.commit("setSelectedType", "Submitted");
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: "bills",
          searchType: ["Submitted"],
        });
      }
    };

    onMounted(async () => {
      await store.dispatch("updateSelectedTab", selectedTab.value);

      try {
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: selectedTab.value.toLowerCase(),
          searchType: ["In progress", "Not Started"],
          id_project: id_project,
        });
        flagLoading.value = false;
      } catch (error) {
        console.error("Error getting tasks:", error);
      }
    });

    onUnmounted(async () => {
      await store.commit("clearDetails");
      await store.commit("clearPagination");
      await store.commit("clearSelectedTab");
      await store.commit("clearClients");
      await store.commit("clearSelectedProject");
    });

    return {
      selectedTab,
      handleTabSelected,
      flagLoading,
    };
  },
};
</script>

<style scoped>
.table-container {
  display: flex;
  justify-content: center;
  margin-left: 2rem;
  margin-right: 2rem;
}
</style>
