<template>
  <div
    class="w-full h-[82px] px-4 pt-6 pb-4 justify-between items-center inline-flex"
  >
    <div
      class="w-[554px] text-neutral-900 text-lg text-left font-semibold font-['Public Sans'] leading-7"
    >
      Client Management
    </div>
    <div class="ml-auto flex justify-start items-center gap-3">
      <div class="relative">
        <input
          type="text"
          placeholder="Search..."
          v-model="searchQuery"
          @input="fetchFiltered"
          class="px-20 py-[9px] bg-white rounded-lg border border-slate-200 pl-10 pr-2"
        />
        <img
          src="/icons/projects/search.svg"
          class="absolute left-3 top-3 w-5 h-5 pointer-events-none"
          style="pointer-events: none"
        />
      </div>
      <button
        @click="newClientView"
        class="px-4 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
      >
        <img
          src="/icons/projects/create-new-project.svg"
          class="w-5 h-5 relative"
        />
        <div class="text-white text-sm font-semibold">Add Client</div>
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import helpers from "../../helpers";

export default {
  emits: ["tab-selected-user-management"],
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const selectedTab = ref("Employees");
    const searchQuery = ref("");

    const selectTab = (tab) => {
      selectedTab.value = tab;
      emit("tab-selected-user-management", tab);
    };

    const fetchFiltered = helpers.createDebouncedFetch(
      store,
      "clients",
      ""
    );

    const toggleModal = () => {
      console.log("open create new task");
    };

    const newClientView = () => {
      router.push({ name: "create-new-client" });
    };

   

   

    return {
      selectedTab,
      selectTab,
      toggleModal,
      newClientView,
      searchQuery,
      
      fetchFiltered
    };
  },
};
</script>

<style scoped>
/* Aquí puedes poner estilos específicos si los necesitas */
</style>import { search } from "core-js/fn/symbol";

