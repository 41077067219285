<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <LoaderSpinner v-if="flagLoading" />
    <div class="w-[572px] bg-white rounded-xl shadow flex flex-col" v-else>
      <div
        class="p-3 bg-slate-100 rounded-tl-xl rounded-tr-xl border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-lg font-semibold">
          Create New Invoice
        </div>
        <button
          @click="closeModal"
          class="p-1 bg-gray-400 rounded-[20px] justify-start items-start gap-2.5 flex"
          style="flex-grow: 0 !important"
        >
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <div
        class="self-stretch h-[74px] px-4 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Enter Rate
          </div>
          <input
            v-model="rate"
            type="number"
            step="0.01"
            placeholder="Enter rate"
            class="self-stretch px-3 py-2 bg-white rounded-lg shadow border border-slate-200"
          />
        </div>
      </div>
      <br />
      <div
        class="self-stretch px-4 pb-6 border-b border-slate-200 flex gap-5"
      >
        <div
          class="flex-1 flex-col gap-1.5"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Start Date
          </div>
          <input
            v-model="start_date"
            type="date"
            placeholder="dd/mm/yyyy"
            class="self-stretch px-3 py-2 bg-white rounded-lg shadow border border-slate-200 w-full"
          />
        </div>
        <div
          class="flex-1 flex-col gap-1.5"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Deadline
          </div>
          <input
            v-model="end_date"
            type="date"
            placeholder="dd/mm/yyyy"
            class="self-stretch px-3 py-2 bg-white rounded-lg shadow border border-slate-200 w-full"
          />
        </div>
      </div>

      <div class="px-4 pb-6 border-b border-slate-200 flex flex-col gap-1.5">
        <div
          class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Notes
        </div>
        <textarea
          v-model="comments"
          placeholder="Enter comments or notes here"
          rows="4"
          class="self-stretch px-3 py-2 bg-white rounded-lg shadow border border-slate-200"
        ></textarea>
      </div>

      <div class="px-4 pt-2 pb-6 flex justify-end gap-3">
        <button
          @click="closeModal"
          class="px-3.5 py-[9px] bg-white rounded-lg shadow border border-slate-200 flex items-center gap-2"
        >
          <div
            class="text-slate-600 text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            Cancel
          </div>
        </button>

        <button
          @click="generateInvoice"
          class="px-3.5 py-[9px] bg-blue_primary rounded-lg shadow flex items-center gap-2"
        >
          <div
            class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            Generate Invoice
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import LoaderSpinner from "../../CircularLoader.vue";
import { useStore } from "vuex";

export default {
  props: {
    isOpen: Boolean,
    id_project: String,
  },
  emits: ["close"],
  components: { LoaderSpinner },
  setup(props, { emit }) {
    const store = useStore();
    const flagLoading = ref(true);
    const rate = ref("");
    const start_date = ref("");
    const end_date = ref("");
    const comments = ref("");

    function closeModal() {
      emit("close");
    }

    async function generateInvoice() {
      flagLoading.value = true;
      const data = {
        id_project: props.id_project,
        start_date: start_date.value,
        end_date: end_date.value,
        rate: parseFloat(rate.value) || 0,
        comments: comments.value,
      };
      const resp = await store.dispatch("addInvoice", data);
      if (resp.ok) {
        flagLoading.value = false;
        closeModal();
      } else {
        flagLoading.value = false;
        closeModal();
      }
    }

    onMounted(async () => {
      flagLoading.value = false;
    });

    return {
      closeModal,
      flagLoading,
      rate,
      start_date,
      end_date,
      comments,
      generateInvoice,
    };
  },
};
</script>

<style scoped>
/* Add necessary styles */
</style>
