<template>
  <div
    class="w-full h-screen flex items-center justify-center bg-[#b7cef2] overflow-hidden"
  >
    <div class="absolute inset-0 flex justify-center items-center">
      <div
        class="absolute w-[569px] h-[585px] bg-[#1762d4] rounded-full blur-[400px]"
      ></div>
      <div
        class="absolute w-[351px] h-[361px] bg-[#1762d4] rounded-full blur-[300px]"
      ></div>
    </div>
    <img
      class="w-[350px] h-[210px] absolute top-[150px] left-[50%] transform -translate-x-[50%]"
      src="/final_teopm.png"
    />
    <div
      class="relative bg-white rounded-xl shadow py-10 px-6 gap-5 w-[448px] h-[370px]"
    >
      <div
        class="text-[#4d586c] text-2xl font-bold font-['Public Sans'] leading-[34px] text-center"
      >
        Forgot Password
      </div>
      <div
        class="w-full text-center text-[#3c4452] text-sm font-normal font-['Public Sans'] leading-snug"
      >
        {{
          isOtpStep
            ? "Enter the OTP we sent to your email"
            : isNewPasswordStep
            ? "Enter the new password"
            : "Confirm your email and we will send you an OTP"
        }}
      </div>
      <div class="w-full flex flex-col justify-center items-center gap-5 mt-5">
        <!-- Email Input -->
        <div
          v-if="!isOtpStep && !isNewPasswordStep"
          class="w-full flex flex-col gap-1.5"
        >
          <label
            class="text-[#4d586c] text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Email
            <input
              v-model="email"
              type="email"
              placeholder="mail@example.com"
              :class="{
                'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                  !isEmailValid(email),
                'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                  isEmailValid(email),
              }"
            />
          </label>
        </div>

        <!-- OTP Input -->
        <div v-if="isOtpStep" class="w-full flex flex-col gap-1.5">
          <label
            class="text-[#4d586c] text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            OTP
            <input
              v-model="otp"
              type="text"
              placeholder="Enter OTP"
              maxlength="4"
              :class="{
                'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                  !isOtpValid(otp),
                'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                  isOtpValid(otp),
              }"
            />
          </label>
        </div>

        <!-- Password and Confirm Password Inputs -->
        <div v-if="isNewPasswordStep" class="w-full flex flex-col gap-1.5">
          <label
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Password
            <input
              v-model="password"
              type="password"
              placeholder="********"
              :class="{
                'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                  !isPasswordValid(password),
                'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                  isPasswordValid(password),
              }"
            />
          </label>
          <label
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Confirm Password
            <input
              v-model="confirmPassword"
              type="password"
              placeholder="********"
              :class="{
                'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                  !confirmPassword || confirmPassword !== password,
                'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                  confirmPassword === password,
              }"
            />
          </label>
        </div>

        <LoaderSpinner v-if="flagLoading" />
        <div class="w-full flex flex-col gap-3" v-else>
          <button
            v-if="!isOtpStep && !isNewPasswordStep"
            @click="sendOtp"
            :disabled="!isEmailValid(email)"
            :class="{
              'px-3.5 py-[9px] bg-blue-300 rounded-lg shadow justify-center items-center gap-2 flex':
                !isEmailValid(email),
              'px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex':
                isEmailValid(email),
            }"
          >
            <div
              class="text-white text-base font-semibold font-['Inter'] leading-normal"
            >
              Next
            </div>
          </button>
          <button
            v-if="isOtpStep"
            @click="verifyOtp"
            :disabled="!isOtpValid(otp)"
            class="px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
          >
            <div
              class="text-white text-base font-semibold font-['Inter'] leading-normal"
            >
              Verify OTP
            </div>
          </button>
          <button
            v-if="isNewPasswordStep"
            @click="newPassword"
            :disabled="!isPasswordValid(password)"
            class="px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
          >
            <div
              class="text-white text-base font-semibold font-['Inter'] leading-normal"
            >
              Confirm new password
            </div>
          </button>
          <button
            class="w-full px-[18px] py-2.5 bg-white rounded-lg shadow border border-[#dfe3f0] text-center"
          >
            <div
              class="text-[#4d586c] text-base font-semibold font-['Inter'] leading-normal"
            >
              Cancel
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { ref } from "vue";
import { useStore } from "vuex";
import LoaderSpinner from "../CircularLoader.vue";
import { useRouter } from "vue-router";

export default {
  components: {
    LoaderSpinner,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const email = ref("");
    const otp = ref("");
    const flagLoading = ref(false);
    const isOtpStep = ref(false);
    const isNewPasswordStep = ref(false);
    const password = ref("");
    const confirmPassword = ref("");

    const isEmailValid = (email) => /\S+@\S+\.\S+/.test(email);
    const isOtpValid = (otp) => otp.length === 4;
    const isPasswordValid = (password) => {
      const minLength = 6;
      const hasUpperCase = /[A-Z]/.test(password);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
      return (
        password.length >= minLength &&
        hasUpperCase &&
        hasSpecialChar &&
        confirmPassword.value === password
      );
    };

    async function sendOtp() {
      flagLoading.value = true;
      try {
        const data = { email: email.value };
        await store.dispatch("forgotPassword", data);
        isOtpStep.value = true;
      } catch (error) {
        console.error("Error sending OTP:", error);
      } finally {
        flagLoading.value = false;
      }
    }

    async function verifyOtp() {
      flagLoading.value = true;
      try {
        const data = { email: email.value, otp: otp.value };
        const otpVerified = await store.dispatch("verifyOtp", data);
        if (otpVerified.data.res) {
          isOtpStep.value = false;
          isNewPasswordStep.value = true;
        } else {
          store.commit("setSnackbar", {
            text: otpVerified.data.message,
            enabled: true,
          });
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
      } finally {
        flagLoading.value = false;
      }
    }

    async function newPassword() {
      flagLoading.value = true;
      try {
        const data = { email: email.value, password: password.value };
        await store.dispatch("setNewPassword", data);
        router.push({ name: "login" });
      } catch (error) {
        console.error("Error updating password:", error);
      } finally {
        flagLoading.value = false;
      }
    }

    return {
      email,
      otp,
      isEmailValid,
      isOtpValid,
      sendOtp,
      verifyOtp,
      flagLoading,
      isOtpStep,
      isNewPasswordStep,
      isPasswordValid,
      password,
      confirmPassword,
      newPassword,
    };
  },
};
</script>
  