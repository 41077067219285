import debounce from "lodash.debounce";
export default {
  convertSeconds(totalSeconds) {
    const numberSeconds = Number(totalSeconds);

    if (isNaN(numberSeconds) || numberSeconds < 0) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }

    const hours = Math.floor(numberSeconds / 3600);
    const minutes = Math.floor((numberSeconds % 3600) / 60);
    const seconds = numberSeconds % 60;

    return { hours, minutes, seconds };
  },
  convertSecondsToDecimalHours(totalSeconds) {
    console.log("🚀 ~ convertSecondsToDecimalHours ~ totalSeconds:", totalSeconds)
    const numberSeconds = Number(totalSeconds);

    if (isNaN(numberSeconds) || numberSeconds < 0) {
      return 0;
    }

    const decimalHours = numberSeconds / 3600;
    return decimalHours;
  },
  convertToDecimalHours(hours, minutes, seconds) {
    const decimalHours =
      parseInt(hours) + parseInt(minutes) / 60 + parseInt(seconds) / 3600;
    return decimalHours;
  },

  formatPhoneNumber(phoneNumber) {
    if (phoneNumber && phoneNumber.length === 10) {
      return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(
        3,
        6
      )}-${phoneNumber.substring(6)}`;
    }
    return phoneNumber;
  },

  formatDateToMMDDYYYY(dateString) {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);

    if (isNaN(date)) {
      return "";
    }

    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  },

  formatDateToDDMMMYYYY(dateString) {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);

    if (isNaN(date)) {
      return "";
    }

    const day = date.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  },

  createDebouncedFetch(store, type, searchType, id_project = null) {
    console.log("🚀 ~ createDebouncedFetch ~ searchType:", searchType);
    const fetch = async (query) => {
      //esto es para tasks
      let type4search = "";
      if (type == "tasks") {
        type4search =
          searchType.value == "Active"
            ? ["In progress", "Not Started"]
            : ["Complete"];
      } else if (type == "users") {
        type4search = searchType.value == "Employees" ? 2 : 3;
      }

      try {
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: type,
          search: query.target.value,
          searchType: type4search != "" ? type4search : searchType.value,
          id_project: id_project,
        });
      } catch (error) {
        console.error("Error fetching :" + type, error);
      }
    };

    return debounce(fetch, 300);
  },
};
