<template>
  <LoaderSpinner v-if="flagLoading" />
  <div class="w-full h-screen relative bg-[#b7cef2] overflow-hidden" v-else>
    <div class="absolute inset-0 flex justify-center items-center">
      <div
        class="absolute w-[569px] h-[585px] bg-[#1762d4] rounded-full blur-[400px]"
      ></div>
      <div
        class="absolute w-[351px] h-[361px] bg-[#1762d4] rounded-full blur-[300px]"
      ></div>
    </div>
    <img
       class="w-[500px] h-[210px] absolute top-[150px] left-[50%] transform -translate-x-[50%]"
      src="/kamvu/logo2x4.png"
    />
    <div
      class="absolute top-[30%] left-[50%] transform -translate-x-[50%] bg-white rounded-xl shadow-lg p-10 flex flex-col gap-5"
    >
      <div class="text-[#4d586c] text-2xl font-bold">Login</div>
      <div class="flex flex-col gap-5">
        <div class="flex flex-col gap-1.5">
          <label class="text-[#4d586c] text-base font-medium">Email</label>
          <input
            v-model="email"
            type="email"
            placeholder="mail@example.com"
            :class="{
              'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                !isEmailValid(email),
              'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                isEmailValid(email),
            }"
          />
        </div>
        <div class="flex flex-col gap-1.5">
          <label class="text-[#4d586c] text-base font-medium">Password</label>
          <input
            v-model="password"
            type="password"
            placeholder="********"
            class="px-3 py-2 bg-white border border-[#dfe3f0] rounded-lg"
          />
        </div>
        <div class="text-xs text-[#475466]">
          Forgot Password?
          <span class="text-[#1762d4] cursor-pointer" @click="forgotPassword"
            >Reset</span
          >
        </div>
      </div>
      <button
        @click="submit"
        class="h-11 px-6 py-2.5 bg-[#1762d4] rounded-lg text-white font-semibold"
      >
        Login
      </button>
      <button
        @click="goToRegister"
        class="h-11 px-6 py-2.5 bg-[#4d586c] rounded-lg text-white font-semibold"
      >
        Account Owner Register
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import LoaderSpinner from "../CircularLoader.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  components: { LoaderSpinner },
  setup() {
    const store = useStore();
    const router = useRouter();
    const email = ref("");
    const password = ref("");
    const flagLoading = ref(false);

    const isEmailValid = (email) => /\S+@\S+\.\S+/.test(email);

    const submit = async () => {
      const data = {
        email: email.value,
        password: password.value,
      };

      try {
        flagLoading.value = true;
        await store.dispatch("setUserAct", data);
        flagLoading.value = false;
      } catch (error) {
        console.error("Error during login:", error);
      }
    };

    function forgotPassword() {
      router.push({ name: "forgot-password" });
    }

    function goToRegister() {
      router.push({ name: "register-owner" });
    }

    return {
      email,
      password,
      isEmailValid,
      submit,
      flagLoading,
      forgotPassword,
      goToRegister,
    };
  },
};
</script>

<style scoped>
/* Add any additional styles here */
</style>