<template>
  <LoaderSpinner v-if="flagLoading" />
  <div
    v-else
    class="w-100 h-[134px] px-4 py-6 bg-white justify-center items-start gap-6 inline-flex"
  >
    <div
      class="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex"
    >
      <div
        class="self-start text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
      >
        {{ data.description }}
      </div>
      <div class="self-stretch justify-between items-center inline-flex">
        <div
          class="w-80 self-stretch flex-col justify-center items-start gap-3 inline-flex"
        >
          <div
            class="self-stretch justify-between items-center gap-2 inline-flex"
          >
            <div
              class="text-slate-500 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Progress
            </div>
            <div
              class="text-blue-700 text-sm font-semibold font-['Public Sans'] leading-snug"
            >
              50%
            </div>
          </div>
          <div
            class="h-3 pr-[151px] bg-slate-100 rounded-[100px] justify-start items-center inline-flex"
          >
            <div class="w-[169px] h-3 bg-blue_primary rounded-[10px]"></div>
          </div>
        </div>
        <div class="flex-col justify-start items-start gap-0.5 inline-flex">
          <div
            class="text-slate-500 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            Status
          </div>
          <div
            v-if="data.status == 'Completed'"
            class="w-30 px-2 py-0.5 bg-slate-100 rounded-md flex-col justify-center items-start gap-2 flex"
          >
            <div
              class="text-emerald-700 text-xs font-medium font-['Public Sans'] leading-[18px]"
            >
              {{ data.status }}
            </div>
          </div>
          <div
            v-if="data.status == 'In Progress'"
            class="w-30 px-2 py-0.5 bg-slate-100 rounded-md flex-col justify-center items-start gap-2 flex"
          >
            <div
              class="text-purple-700 text-xs font-medium font-['Public Sans'] leading-[18px]"
            >
              {{ data.status }}
            </div>
          </div>
          <div
            v-if="data.status == 'Active'"
            class="w-30 px-2 py-0.5 bg-slate-100 rounded-md flex-col justify-center items-start gap-2 flex"
          >
            <div
              class="text-blue-700 text-xs font-medium font-['Public Sans'] leading-[18px]"
            >
              {{ data.status }}
            </div>
          </div>
        </div>
        <div class="flex-col justify-center items-start gap-1 inline-flex">
          <div
            class="text-slate-500 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            Task
          </div>
          <div
            class="text-neutral-900 text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            144 /1200
          </div>
        </div>
        <div class="flex-col justify-center items-start gap-1 inline-flex">
          <div
            class="text-slate-500 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            Created
          </div>
          <div
            class="text-neutral-900 text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            {{ helpers.formatDateToDDMMMYYYY(data.start_date) }}
          </div>
        </div>
        <div class="flex-col justify-center items-start gap-1 inline-flex">
          <div
            class="text-slate-500 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            Due Date
          </div>
          <div
            class="text-neutral-900 text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            {{ helpers.formatDateToDDMMMYYYY(data.due_date) }}
          </div>
        </div>
        <div class="flex-col justify-center items-start gap-1 inline-flex">
          <div
            class="text-slate-500 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            Employees
          </div>
          <div class="w-[67px] h-6 relative">
            <img
              v-for="(image, index) in imagesEmployees.slice(0, 5)"
              :key="index"
              :src="image.photo"
              class="w-6 h-6 absolute rounded-[200px]"
              :style="{ left: `${index * 14}px`, top: '0' }"
              :title="image.fullname"
            />
          </div>
        </div>
        <div class="flex-col justify-center items-start gap-1 inline-flex">
          <div
            class="text-slate-500 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            Contractors
          </div>
          <div class="w-[67px] h-6 relative">
            <img
              v-for="(image, index) in imagesContractors.slice(0, 5)"
              :key="index"
              :src="image.photo"
              class="w-6 h-6 absolute rounded-[200px]"
              :style="{ left: `${index * 14}px`, top: '0' }"
              :title="image.fullname"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="justify-start items-start gap-4 flex">
      <button
        class="p-1.5 bg-slate-100 rounded-md justify-center items-center gap-2 flex"
      >
        <img src="/icons/projects/bookmark.svg" class="w-5 h-5 relative" />
      </button>
      <button
        class="p-1.5 bg-slate-100 rounded-md justify-center items-center gap-2 flex"
      >
        <img src="/icons/projects/share.svg" class="w-5 h-5 relative" />
      </button>
    </div>
  </div>
</template>
<script>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import helpers from "../../helpers";
import LoaderSpinner from "../CircularLoader.vue";
export default {
  components: {
    LoaderSpinner,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const id = route.params.id;
    const store = useStore();
    const data = computed(() => store.getters.detailsData);
    const flagLoading = ref(false);
    const imagesEmployees = ref([]);
    const imagesContractors = ref([]);
    const id_user = computed(() => store.state.user.uid);
    const user_type = computed(() => store.state.user.type);

    onMounted(async () => {
      flagLoading.value = true;
      const response = await store.dispatch("fetchProject", {
        id_project: id,
        id_user: id_user.value,
        user_type: user_type.value,
      });
      if (response.ok === false) {
        router.push({ name: "projects" });
        return;
      }

      if (data.value && Array.isArray(data.value.ProjectResources)) {
        for (const resource of data.value.ProjectResources) {
          if (resource.User && resource.User.photo) {
            if (resource.User.user_type_id === 2) {
              imagesEmployees.value.push({
                photo: resource.User.photo,
                fullname: resource.User.name + " " + resource.User.last_name,
              });
            } else if (resource.User.user_type_id === 3) {
              imagesContractors.value.push({
                photo: resource.User.photo,
                fullname: resource.User.name + " " + resource.User.last_name,
              });
            }
          }
        }
      } else {
        console.log(
          "No resources found or data.ProjectResources is not an array"
        );
      }

      flagLoading.value = false;
    });

    return {
      data,
      helpers,
      flagLoading,
      imagesEmployees,
      imagesContractors,
    };
  },
};
</script>