<template>
  <div>
    <div
      class="w-full  h-7 px-4 justify-start items-center gap-[1123px] inline-flex"
    >
      <div
        class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7 pb-5"
      >
        Overview
      </div>
    </div>
    <div
      class="w-[1156px] h-[184px] px-4 justify-start items-start gap-5 inline-flex"
    >
      <div
        class="grow shrink basis-0 p-4 bg-white rounded-xl border border-slate-200 flex-col justify-start items-start gap-5 inline-flex"
      >
        <div
          class="w-[197px] flex items-center justify-between text-slate-600 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          <span>Total Hours Worked</span>
          <img
            src="/icons/projects/action-dots.svg"
            class="w-5 h-5 pointer-events-auto cursor-pointer"
          />
        </div>

        <div class="self-stretch justify-start items-end gap-4 inline-flex">
          <div
            class="w-[105px] flex-col justify-start items-start gap-4 inline-flex"
          >
            <div class="w-[124px]">
              <span
                class="text-neutral-900 text-4xl font-medium font-['Public Sans'] leading-10"
              >
                420
              </span>
              <span
                class="text-neutral-900 text-base font-medium font-['Public Sans'] leading-10"
              >
                hours
              </span>
            </div>

            <div
              class="self-stretch h-[50px] flex-col justify-center items-start gap-2 flex"
            >
              <div class="justify-center items-center gap-0.5 inline-flex">
                <img src="/icons/dashboard/up.svg" class="w-5 h-5 relative" />
                <div
                  class="text-center text-blue-700 text-sm font-medium font-['League Spartan'] leading-[18px]"
                >
                  40%
                </div>
              </div>
              <div
                class="self-stretch text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
              >
                last week
              </div>
            </div>
          </div>
          <img src="/icons/dashboard/chart.svg" />
        </div>
      </div>
      <div
        class="grow shrink basis-0 p-4 bg-white rounded-xl border border-slate-200 flex-col justify-start items-start gap-5 inline-flex"
      >
        <div
          class="w-[197px] flex items-center justify-between text-slate-600 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          <span>Total Timesheets Generated</span>
          <img
            src="/icons/projects/action-dots.svg"
            class="w-5 h-5 pointer-events-auto cursor-pointer"
          />
        </div>

        <div class="self-stretch justify-start items-end gap-4 inline-flex">
          <div
            class="w-[105px] flex-col justify-start items-start gap-4 inline-flex"
          >
            <div class="w-[124px]">
              <span
                class="text-neutral-900 text-4xl font-medium font-['Public Sans'] leading-10"
              >
                420
              </span>
              <span
                class="text-neutral-900 text-base font-medium font-['Public Sans'] leading-10"
              >
                hours
              </span>
            </div>

            <div
              class="self-stretch h-[50px] flex-col justify-center items-start gap-2 flex"
            >
              <div class="justify-center items-center gap-0.5 inline-flex">
                <img src="/icons/dashboard/up.svg" class="w-5 h-5 relative" />
                <div
                  class="text-center text-blue-700 text-sm font-medium font-['League Spartan'] leading-[18px]"
                >
                  40%
                </div>
              </div>
              <div
                class="self-stretch text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
              >
                last week
              </div>
            </div>
          </div>
          <img src="/icons/dashboard/chart.svg" />
        </div>
      </div>
      <div
        class="grow shrink basis-0 p-4 bg-white rounded-xl border border-slate-200 flex-col justify-start items-start gap-5 inline-flex"
      >
        <div
          class="w-[197px] flex items-center justify-between text-slate-600 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          <span>Total Bills Approved</span>
          <img
            src="/icons/projects/action-dots.svg"
            class="w-5 h-5 pointer-events-auto cursor-pointer"
          />
        </div>

        <div class="self-stretch justify-start items-end gap-4 inline-flex">
          <div
            class="w-[105px] flex-col justify-start items-start gap-4 inline-flex"
          >
            <div class="w-[124px]">
              <span
                class="text-neutral-900 text-4xl font-medium font-['Public Sans'] leading-10"
              >
                420
              </span>
              <span
                class="text-neutral-900 text-base font-medium font-['Public Sans'] leading-10"
              >
                hours
              </span>
            </div>

            <div
              class="self-stretch h-[50px] flex-col justify-center items-start gap-2 flex"
            >
              <div class="justify-center items-center gap-0.5 inline-flex">
                <img src="/icons/dashboard/up.svg" class="w-5 h-5 relative" />
                <div
                  class="text-center text-blue-700 text-sm font-medium font-['League Spartan'] leading-[18px]"
                >
                  40%
                </div>
              </div>
              <div
                class="self-stretch text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
              >
                last week
              </div>
            </div>
          </div>
          <img src="/icons/dashboard/chart.svg" />
        </div>
      </div>
      <div
        class="grow shrink basis-0 p-4 bg-white rounded-xl border border-slate-200 flex-col justify-start items-start gap-5 inline-flex"
      >
        <div
          class="w-[197px] flex items-center justify-between text-slate-600 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          <span>Total Projects</span>
          <img
            src="/icons/projects/action-dots.svg"
            class="w-5 h-5 pointer-events-auto cursor-pointer"
          />
        </div>

        <div class="self-stretch justify-start items-end gap-4 inline-flex">
          <div
            class="w-[105px] flex-col justify-start items-start gap-4 inline-flex"
          >
            <div class="w-[124px]">
              <span
                class="text-neutral-900 text-4xl font-medium font-['Public Sans'] leading-10"
              >
                420
              </span>
              <span
                class="text-neutral-900 text-base font-medium font-['Public Sans'] leading-10"
              >
                hours
              </span>
            </div>

            <div
              class="self-stretch h-[50px] flex-col justify-center items-start gap-2 flex"
            >
              <div class="justify-center items-center gap-0.5 inline-flex">
                <img src="/icons/dashboard/up.svg" class="w-5 h-5 relative" />
                <div
                  class="text-center text-blue-700 text-sm font-medium font-['League Spartan'] leading-[18px]"
                >
                  40%
                </div>
              </div>
              <div
                class="self-stretch text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
              >
                last week
              </div>
            </div>
          </div>
          <img src="/icons/dashboard/chart.svg" />
        </div>
      </div>
    </div>
  </div>
</template>